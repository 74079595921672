<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12 view-action">
      <div class="card card-w-title">
        <Toolbar class="toolbar-top">
          <template #left>
            <div class="manager-title">Chi tiết lịch hẹn <b>{{code}}</b></div>
          </template>
          <template #right>
            <Button
              label="Quay lại"
              @click="backToList()"
              class="p-button-warning callback-btn"
            />
          </template>
        </Toolbar>
        <Panel>
          <template slot="header">
              <h2>Thông tin cá nhân</h2>
          </template>
          <div class="p-formgrid">
            <div class="p-card-field p-grid">
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Khách hàng</label>
                <div class="p-col">
                  <label class="p-inputtext p-component p-filled disabled">{{ full_name }}</label>
                </div>
              </div>
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Email</label>
                <div class="p-col">
                  <label class="p-inputtext p-component p-filled disabled">{{email}}</label>
                </div>
              </div>
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Họ</label>
                <div class="p-col">
                  <label class="p-inputtext p-component p-filled disabled">{{ first_name }}</label>
                </div>
              </div>
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Tên</label>
                <div class="p-col">
                  <label class="p-inputtext p-component p-filled disabled">{{ last_name }}</label>
                </div>
              </div>
              
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Số điện thoại</label>
                <div class="p-col">
                    <label class="p-inputtext p-component p-filled disabled">{{ phone }}</label>
                </div>
              </div>
              
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Ngày sinh</label>
                <div class="p-col">
                  <label class="p-inputtext p-component p-filled disabled">{{ full_birthday }}</label>
                </div>
              </div>
              
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Giới tính</label>
                <div class="p-col">
                  <label class="p-inputtext p-component p-filled disabled">{{ gender_name }}</label>
                </div>
              </div>
              
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Mã khách hàng</label>
                <div class="p-col">
                  <label class="p-inputtext p-component p-filled disabled">{{ patient_code }}</label>
                </div>
              </div>
            </div>
          </div>
        </Panel>
        <Panel>
          <template slot="header">
            <h2>Thông tin lịch hẹn</h2>
          </template>
          <div class="p-formgrid">
            <div class="p-card-field p-grid">
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Chi nhánh</label>
                <div class="p-col">
                  <label class="p-inputtext p-component p-filled disabled">{{ branch_name }}</label>
                </div>
              </div>
              
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Trạng thái</label>
                <div class="p-col">
                  <label class="p-inputtext p-component p-filled disabled">{{ status_name }}</label>
                </div>
              </div>
            </div>
            <div class="p-card-field p-grid">
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Ngày hẹn</label>
                <div class="p-col">
                  <label class="p-inputtext p-component p-filled disabled">{{ date }}</label>
                </div>
              </div>
              
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Giờ hẹn</label>
                <div class="p-col">
                  <label class="p-inputtext p-component p-filled disabled">{{ time_name }}</label>
                </div>
              </div>
              
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Có đi cùng bạn không?</label>
                <div class="p-col">
                  <label class="p-inputtext p-component p-filled disabled">{{ go_with_name }}</label>
                </div>
              </div>
            </div>
            
          </div>
        </Panel>
        <Panel>
          <template slot="header">
            <h2>Thông tin dịch vụ</h2>
          </template>
          <div class="p-formgrid">
            <div class="p-card-field p-grid">
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Loại dịch vụ</label>
                <div class="p-col">
                  <label class="p-inputtext p-component p-filled disabled">{{ type_service_name }}</label>
                </div>
              </div>
              
              <div class="p-field p-col-6">
                <label class="p-col-fixed txt-left">Dịch vụ</label>
                <div class="p-col">
                  <label class="p-inputtext p-component p-filled disabled">{{ service_name }}</label>
                </div>
              </div>
              
              <div class="p-field p-col-12">
                <div class="p-col">
                  <DataTable :value="listCategory" :paginator="false" class="p-datatable-category" :rows="50"
                    dataKey="id" :filters="filters" style="width: 100%">
                    <template #empty>
                        Không có dữ liệu.
                    </template>
                    <Column header="STT" headerStyle="width: 5%;text-align:center;" bodyStyle="width: 5%; text-align:center;">
                        <template #body="{ data, frozenRow, index }">
                            {{ ( index + 1) }}
                        </template>
                    </Column>
                    <Column field="question_title" header="Câu hỏi" headerStyle="width: 85%;text-align:center;">
                        <template #body="slotProps">
                            {{slotProps.data.question_title}}
                        </template>
                    </Column>
                    <Column field="answer" header="Trả lời" headerStyle="width: 10%;text-align:center;" bodyStyle="width: 10%; text-align:center;">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.answer !== null">
                              {{slotProps.data.answer ? 'Có' : 'Không'}}
                            </span>
                        </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            
            </div>
          </div>
        </Panel>
      </div>
    </div>
    <Dialog
      header="Xác nhận"
      :visible.sync="showDeleteDialog"
      :contentStyle="{overflow: 'visible'}"
      :modal="true"
    >
      Bạn có chắc muốn xóa người dùng <strong>{{this.deleteName}}</strong>
      <template #footer>
        <Button label="Xóa" @click="deleteData()" icon="pi pi-check" class="p-button-danger" />
        <Button
          label="Hủy"
          @click="showDeleteDialog = false"
          icon="pi pi-times"
          class="p-button-warning callback-btn"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
// import BaseDatatable from './list'
export default {
  components: {
    // BaseDatatable
  },
  data() {
    return {
      code: '',
      email: '',
      first_name: '',
      last_name: '',
      service_name: '',
      type_service_name: '',
      
      patient_code: '',
      gender_name: '',
      full_birthday: '',
      
      go_with_name: '',
      time_name: '',
      date: '',
      prefix_phone: '',
      branch_name: '',
      phone: '',
      // email: '',
      uid: '',
      uids: {},
      images: [],
      models: {},
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      pagination: true, // true cho phép phân trang
      paginationRows: 10, // số dòng trên 1 trang,
      listData: [],
      status_name: null,
      componentKey: 0,
      listCategory: [],
      filters: {},
      full_name: null,
      time_zone_local : moment.tz.guess(true),
      listStatus: [{value:1,label:"Đã đặt"},{value:2,label:"Đã xác nhận"},{value:3, label:"Hủy hẹn"},{value:4,label:"Hoàn thành"}],
    }
  },
  async mounted () {
    // this.dataModel = DataServices.getModel('users');
    if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
      await this.getData(this.$route.params.id);
    }
  },
  methods: {
    getField(col) {
      return col.key;
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getHeaderStyleView() {
      var style = "display : none";
      return style;
    },
    getDataRows(col, row) {
      var value = '';
      if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
        var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
        value = result;
      }
      return value
    },
    clickEdit() {
      this.$router.push({ path: '/admin/list-booking/edit/' + this.models.id, params: {id: this.models.id}});
    },
    backToList() {
      this.$router.push({ path: '/admin/list-booking' });
    },
    clickDelete() {
      this.deleteId = this.models.id
      this.deleteName = this.models.fullname
      this.showDeleteDialog = true
    },
    deleteData() {
      this.showDeleteDialog = false
      var query = DataServices.updateData('users')
      let variables = {
        'data_update': {
          'deleted': true,
          'active': false,
          'status': 0
        },
        'where_key': {
          'id': {'_eq': this.models.id}
        }
      };
      this.$apollo.mutate({
        mutation: query,
        variables: variables
      }).then((res) => {
        if (res.data[Object.keys(res.data)].affected_rows) {
          let acc_variables = {
            'data_update': {
              'deleted': true
            },
            'where_key': {
              'uid': {'_eq': this.models.id}
            }
          };
          this.$apollo.mutate({
            mutation: DataServices.updateData('accounts'),
            variables: acc_variables
          }).then((res) => {
            // console.log(res);
            if (res.data[Object.keys(res.data)].affected_rows) {
              this.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Xóa dữ liệu thành công', life: 3000 })
            } else this.$toast.add({ severity: 'error', summary: 'Thất bại', detail: 'Xóa dữ liệu thất bại', life: 3000 })
            this.backToList();
          }).catch((error) => {
            console.log(error);
          })
        }
      }).catch((error) => {
        console.error(error);
      })
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('booking', {"fields" : "id first_name last_name gender phone prefix_phone user {id first_name last_name} patient_code go_with email code date_booking patient_code full_birthday status booking_lines {service {id name}} time {id name} branch {id name} type_service {id name} booking_questions {question_title answer}"}),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        this.code = this.models.code;
        this.first_name = this.models.first_name;
        this.last_name = this.models.last_name;
        this.email = this.models.email;
        this.patient_code = this.models.patient_code;
        this.prefix_phone = this.models.prefix_phone;
        this.phone = this.models.phone;
        this.patient_code = this.models.patient_code;
        this.full_birthday = moment.tz(this.models.full_birthday, this.time_zone_local).format("DD/MM/yyyy");
        this.date = moment.tz(this.models.date_booking, this.time_zone_local).format("DD/MM/yyyy");
        if(this.models.booking_questions !== undefined && this.models.booking_questions !== null){
          this.listCategory = this.models.booking_questions;
        }
        var service_name_arr = [];
        if(this.models.time !== undefined && this.models.time !== null){
          this.time_name = this.models.time.name;
        }
        if(this.models.branch !== undefined && this.models.branch !== null){
          this.branch_name = this.models.branch.name;
        }
        if(this.models.type_service !== undefined && this.models.type_service !== null){
          this.type_service_name = this.models.type_service.name;
        }
        if (this.models.user !== undefined && this.models.user !== null){
            this.full_name = this.models.user.first_name + ' ' + this.models.user.last_name;
        }
        if (this.models.gender == 1){
            this.gender_name = "Nam"
        } else if(this.models.gender == 2){
            this.gender_name = "Nữ"
        } else if(this.models.gender == 3){
            this.gender_name = "Khác"
        } else {
            this.gender_name = "Chưa chọn"
        }
        if (this.models.status == 1){
            this.status_name = "Đã đặt"
        } else if(this.models.status == 2){
            this.status_name = "Đã xác nhận"
        } else if(this.models.status == 3){
            this.status_name = "Hủy đặt"
        } else if(this.models.status == 4){
            this.status_name = "Hoàn thành"
        }
        this.go_with_name = this.models.go_with == true ? 'Có' : 'Không';
        console.log(this.listCategory);
        if(this.models.booking_lines !== undefined && this.models.booking_lines !== null){
          for (var k = 0; k < this.models.booking_lines.length; k++) {
            if (this.models.booking_lines[k].service !== undefined && this.models.booking_lines[k].service.name) {
              service_name_arr.push(this.models.booking_lines[k].service.name);
            }
          }
        }
        this.service_name = service_name_arr.join(', ');
        console.log(service_name_arr);
        // console.log("dsada",this.models.users_category)

        this.componentKey++;
      }
    }
  },
  apollo: {
  }
}
</script>
<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.view-action{
  .toolbar-top{background: #fff; padding: 0; border: 0 none;margin-bottom: 16px; padding-bottom: 10px; border-bottom: 1px solid #EEEEEE;
    .manager-title{border: 0 none; padding: 0; margin: 0;}
    .p-toolbar-group-right {
      .p-button{margin-right: 8px; width: auto;
        &:last-child{margin-right: 0;}
      }
    }
  }
  .p-tabview{
    .p-tabview-panels{ padding: 0;}
    .p-tabview-nav{
      li{margin-right: 8px;
        .p-tabview-nav-link{border: 1px solid #B4B4B4; border-radius: 5px 5px 0px 0px !important; background: #eee; font-size: 15px;
          &:hover{text-decoration: none;}
        }
        &.p-highlight{
          .p-tabview-nav-link{background: #00A8FF !important;border: 1px solid #B4B4B4; border-radius: 5px;}
        }
        &:hover{
          .p-tabview-nav-link{background: #00A8FF !important; color: #fff !important;}
        }
      }
    }
  }
  .p-formgrid { margin: 0;
    .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
    .p-card-field{ padding: 24px;
      .p-field{
        .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
        .p-inputtext p-component p-filled{width:500px !important; border-radius: 5px;
          &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px; color: #707070; height: 40px;}
          &.rows-4{height: 80px;}
          .file-select{margin: 0;
            input[type="file"]{display: none;}
            .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
          }
        }
        .box-images{ margin-top: 10px; position: relative;
          .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
            &.p-col-5{width: 18%;;}
            img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
            .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
              img{vertical-align: top;}
            }
          }
        }
      }
    }
  }
  .p-rates{
    .top-rates{ padding: 16px;color: #000;border-bottom: 1px solid #B4B4B4; 
      label{margin: 0;}
      span{font-weight: 600; padding-left: 100px;}
    }
    .list-rates{padding: 16px;}
  }
  .table-view >.p-datatable .p-datatable-tbody > tr > td {
    color: #707070 !important;
  }
  .p-inputtext {
    min-height: 40px;
  }
  .p-toolbar-group-right {
    flex: inherit !important;
  }
}
</style>